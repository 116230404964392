import React from 'react'
import Card from 'react-bootstrap/Card';
import './styles/style.css';
import logo from './styles/images/iconol.png'

function App() {

    return (
        <div>
            <div className='header'>
            <p className='titulo'><img src={logo} width="30" height="30" alt='2dine'/> 2dine</p>
            </div>

            <div style={{ margin: '2%' }}>
                <Card>
                    <Card.Header>Aviso de Privacidad</Card.Header>
                    <Card.Body>
                        <blockquote className="blockquote mb-0">
                            <p><b>
                                “KOOT TECH”, S.A. de C.V.</b> a quien en lo sucesivo se le denominará como “KOOT TECH”; con el objeto de dar tratamiento legítimo, controlado e informado de sus datos personales, informamos nuestro compromiso con su privacidad y el derecho de ejercer su autodeterminación informativa, por lo que el presente “Aviso de Privacidad” tiene como objeto informarle sobre el tratamiento que se le dará a sus datos personales cuando los mismos sean recabados, utilizados, almacenados y/o transferidos por “KOOT TECH”.
                            </p><p>
                                <b>Responsable del tratamiento de Datos Personales</b>
                            </p><p>
                                “KOOT TECH” con domicilio en calle James Sullivan 55, Col. San Rafael, alcaldía Cuauhtémoc, C.P. 06470, en la Ciudad de México; es la encargada del uso y protección de los datos personales que es usted titular.
                            </p><p>
                                <b>Consentimiento</b>
                            </p><p>
                                Al utilizar 2dine, prestas tu consentimiento a la recopilación y utilización de tus Datos Personales, en virtud del presente documento. Si no estás de acuerdo con las disposiciones de esta Declaración de Privacidad, no utilices la aplicación.
                                En caso de cambios en esta Declaración de Privacidad, las modificaciones se publicarán en este sitio web para que siempre tengas conocimiento de qué Datos Personales recopilamos y cómo los usamos. Por lo tanto, te instamos a revisar periódicamente las actualizaciones de la Declaración de Privacidad.
                                Conforme lo requiera la legislación aplicable, también solicitaremos tu consentimiento expreso para procesar determinados Datos Personales recopilados por este aplicativo o brindados por ti de forma voluntaria. Ten en cuenta que dicho consentimiento será completamente voluntario.
                            </p><p>
                                <b>Dónde se aplica esta Política de privacidad</b>
                            </p><p>
                                Esta Política de privacidad aplica para los sitios web, las apps, los eventos y otros servicios que operamos bajo la marca 2dine.
                                </p><p>
                                <b>Información que recopilamos</b>
                                </p><p>
                                </p><p>
                                <b>Recopilación y Procesamiento de Datos Personales</b>
                                </p><p>
                                Para brindar un mejor servicio y comprender sus necesidades e intereses, “KOOT TECH” recopila, exporta y utiliza Datos Personales con la debida notificación (tal como se establece en el presente documento) y el consentimiento necesario, conforme lo requiera la legislación aplicable, junto con las presentaciones ante las autoridades de protección de datos, según corresponda.
                                Al utilizar 2dine, podremos registrar tu dirección de IP y utilizar las cookies y otras tecnologías de Internet para recopilar información sobre nuestros visitantes y sus intereses.
                                Luego de prestar su consentimiento (siempre que la legislación así lo requiera), “KOOT TECH” podrá añadir la información recopilada al perfil de usuario que “KOOT TECH” conserva sobre usted.
                                <ul>
                                <li>Asimismo, podemos recopilar y procesar cualquier Dato Personal que nos concedas voluntariamente de acuerdo a lo estipulado a continuación:</li>
                                <li>Cuando creas tu cuenta, nos proporcionas tu dirección de correo electrónico, así como algunos detalles básicos necesarios para el funcionamiento adecuado de nuestros servicios, como tu género y  fecha de nacimiento.</li>
                                <li>Cuando completas tu perfil nos compartes más detalles sobre ti como tu ocupación, tu educación, tus intereses en 2dine y puedes incluso dar más detalles de tu perfil en el apartado de Acerca de mí. Cuando nos proporcionas dicha información, estás de acuerdo con que los utilicemos para los fines identificados y establecidos en esta Política de privacidad.</li>
                                <li>Cuando te suscribes a un servicio pagado o realizas una compra directamente de nosotros, nos proporcionas información relacionada con las compras que realizas y a nuestros procesadores de pagos información como tu número de tarjeta de débito o crédito u otra información financiera, necesarios para poder garantizarte una experiencia diferenciada con 2dine Premium.</li>
                                <li>Cuando participas en encuestas, grupos focales o estudios de mercado, nos brindas información sobre nuestros productos y servicios, respuestas a nuestras preguntas y testimonios.</li>
                                <li>Cuando participas en nuestras promociones, eventos o concursos, recopilamos la información que utilizas para registrarte o participar.</li>
                                <li>Si te pones en contacto con nuestro equipo de atención al cliente, recopilaremos la información que nos proporciones durante esta interacción.</li>
                                <li>Si compartes con nosotros información sobre otras personas (por ejemplo, si proporcionas la información de contacto de un amigo para una función determinada), procesaremos esta información de tu parte para completar tu solicitud.</li>
                                <li>Por supuesto, también procesamos tus chats con otros miembros, así como el contenido que publicas para operar y asegurar nuestros servicios, y para mantener a nuestra comunidad segura.</li>
                                <li>La información que recibimos de otras personas</li>
                                <li>Además de la información que nos proporcionas directamente, también recibimos información tuya de otras personas, como por ejemplo:</li>
                                <li>Usuarios: Los usuarios pueden proporcionarnos información sobre ti a medida que utilicen nuestros servicios. Por ejemplo, al interactuar contigo o al enviar una denuncia sobre ti.</li>
                                <li>Redes sociales: podrías decidir compartir información con nosotros a través de tus cuentas de redes sociales. Por ejemplo, si deseas crear e iniciar sesión en tu cuenta de Tinder a través de tus redes sociales u otra cuenta (como Google o Linkedin) o subir a nuestros servicios información como fotos de una de tus redes sociales.</li>
                                </ul>
                            </p><p>
                                <b>Uso y Objetivo de los Datos Personales Recopilados</b>
                            </p><p>
                                La información que recopila “KOOT TECH” para comprender tus necesidades e intereses le permite brindar una experiencia personalizada y constante. Utilizaremos esa información únicamente del modo descrito en la Declaración de Privacidad y/o en el modo especificado al momento de realizar la recopilación. No modificaremos posteriormente ni sin tu consentimiento el modo en que los Datos Personales se utilizan, conforme lo requiere la legislación aplicable. Tus Datos Personales se utilizarán exclusivamente para los siguientes objetivos:
                            </p><p>
                                <ul>
                                    <li>Para poder crear un perfil de usuario en 2dine.</li>
                                    <li>Para poder conectarte con usuarios cercanos a tu ubicación.</li>
                                    <li>Para adaptar la información sobre nuestros productos y servicios a sus intereses personales.</li>
                                    <li>Para proporcionar una experiencia de usuario personalizada y visualizar el contenido protegido.</li>
                                    <li>Para proporcionar la capacidad de contactarlo, con el objeto de responder sus preguntas, y proveer la información de envío y facturación, y brindar asistencia y observaciones al cliente.</li>
                                    <li>Para cumplir con las obligaciones contractuales.</li>
                                </ul>
                            </p><p>

                            </p><p>
                                <b>Direcciones de Correo Electrónico</b>
                            </p><p>
                                Si optas por proporcionar tu dirección de correo electrónico, nos comunicaremos contigo por esa vía. Conforme a tu configuración de la aplicación de correo electrónico, es posible que determinados datos sobre ti se transfieran automáticamente al enviar un correo electrónico a “KOOT TECH”.
                                Si optas por inscribirse a servicios prestados por terceros, es posible que debamos enviar algunos datos de tu registro en “KOOT TECH” al proveedor de dichos servicios, incluida tu dirección de correo electrónico.
                            </p><p>
                                Direcciones de IP
                            </p><p>
                                Utilizamos las direcciones de IP para diagnosticar problemas, para establecer el país desde el cual un usuario ingresa a nuestros sitios web y servicios, a fin de proporcionar las declaraciones legales adecuadas, para administrar el sitio web, y para recopilar la información demográfica.
                                También podemos utilizar las direcciones de IP u otra información que haya compartido en este sitio web o en cualquier otra ocasión anterior, para determinar qué páginas de nuestro sitio visitó y qué temas pueden interesarle, con el objeto de brindarle información sobre los productos y servicios relevantes.
                                “KOOT TECH” solo recopiló información relacionada con su visita al sitio de “KOOT TECH”. No rastreamos ni recopilamos Datos Personales de las visitas que realiza a empresas o entidades externas a “KOOT TECH”.
                            </p><p>
                                Seguridad de los Datos Personales
                            </p><p>
                                “KOOT TECH” se compromete a proteger los Datos Personales que nos compartes. “KOOT TECH” utiliza una combinación de medidas organizativas, procedimientos y tecnologías de seguridad estándar de la industria para proteger los Datos Personales de la divulgación, el uso y el acceso no autorizado. “KOOT TECH” respalda la seguridad en línea mediante el uso de tecnología de servidores seguros porque queremos garantizar la seguridad de los datos. Contamos con instalaciones y arreglos de seguridad de última generación en los sitios de “KOOT TECH” para evitar los abusos.
                                Nuestros empleados y procesadores de datos están obligados a observar sus derechos de confidencialidad y privacidad.
                            </p><p>
                                Enlaces a Otros Sitios
                            </p><p>
                                La aplicación de 2dine puede incluir enlaces a sitios externos (es decir, sitios de empresas que no pertenecen a “KOOT TECH”). “KOOT TECH” no será responsable por las prácticas de privacidad o el contenido de otros sitios web ajenos “KOOT TECH”. Por lo tanto, se recomienda que lea cuidadosamente las declaraciones de privacidad de tales sitios externos.
                            </p><p><b>
                                Retención de Datos
                            </b></p><p>
                                “KOOT TECH” no retendrá tus Datos Personales por más tiempo del necesario para cumplir con los objetivos por los cuales los recopiló o en virtud de lo que exijan las leyes y regulaciones aplicables. En particular, si no existe ninguna obligación legal contradictoria, “KOOT TECH” eliminará los Datos Personales una vez que informe a “VISUAL” que no quiere que continúe procesando sus Datos Personales. Envíe dicha solicitud a contacto@koottech.com. Tenga en cuenta que, en este caso, el uso de determinados servicios puede verse afectado de manera parcial o total. En caso de que existiera una obligación legal contradictoria que exija a “KOOT TECH” retener sus Datos Personales, “KOOT TECH” los bloqueará para evitar su procesamiento y, luego, eliminará dichos Datos Personales cuando haya cesado el requisito de retenerlos.
                            </p><p><b>
                                Seguridad de la Información.
                            </b></p><p>
                                En desarrollo del principio de seguridad, “KOOT TECH” ha adoptado medidas técnicas, administrativas y humanas razonables para proteger la información de los Titulares e impedir adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. El acceso a los datos personales está restringido a sus Titulares y “KOOT TECH” no permitirá el acceso a esta información por parte de terceros, a excepción de un pedido expreso del Titular de los datos o personas legitimadas de conformidad con la normatividad nacional.
                            </p><p><b>
                                Vigencia de la Política.
                            </b></p><p>
                                Cualquier cambio sustancial en esta política será dado a conocer mediante un anuncio dentro del aplicativo de 2dine.
                                El término de las autorizaciones sobre el uso de los datos personales se entiende por el término de la relación comercial o de la vinculación al servicio y durante el ejercicio del objeto social de la compañía, salvo aquellos casos en los que la ley disponga un término distinto.
                                {' '}
                            </p>
                        </blockquote>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}



export default App;